import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import './MyVideoPlayer.css';

function MyVideoPlayer({ otherVignetteUrl, url, viewed }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const size = "100%";

    useEffect(() => setIsPlaying(false), [url])

    return (
        <div className="player-wrapper">
            {(!isPlaying && otherVignetteUrl) && <img className="react-player" width={size} src={otherVignetteUrl}></img>}
            <div className={(!isPlaying && otherVignetteUrl) ? 'hidden' : undefined}>
                <ReactPlayer
                    width={size}
                    height={size}
                    playing={true}
                    controls
                    className="react-player"
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    onEnded={() => {setIsPlaying(false); viewed()}}
                    url={url}
                />
            </div>
        </div>
    );
}

export default MyVideoPlayer;