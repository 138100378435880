import React, { useState, useEffect } from 'react';
import MyVideoPlayer from './MyVideoPlayer';
import Recomendation from './Recomendation';
import Description from './Description';
import SpeControls from './SpeControls';
import '../App.css';
//import useFetch from '../api/useFetch';
import { useLocation, useNavigate } from 'react-router-dom'

const defaultVideo = {
    'ID': 0,
    'title': '',
    'streamUrl': '',
    'streamHdUrl': '',
    'imgUrl': '',
    'description': '',
    'tags': [],
    'duration': '',
    'date': ''
}

function PlayPage(props) {
    const [currentVideo, setCurrentVideo] = useState(defaultVideo);
    const [otherVideo, setOtherVideo] = useState(defaultVideo);
    const [hd, setHd] = useState(true);
    const [recommendationItemsDatas, setRecommendationItemsDatas] = useState([]);
    console.log("-- Rendeing PlayPage")

    const query = new URLSearchParams(useLocation().search);
    const videoId = query.get('v');
    const search = query.get('q');
    const videoApiUrl = `/videos?${query.toString()}`
    const navigate = useNavigate();
    //const {
    //    data: videoItemsDatas,
    //    loading: loadingVideoItemsDatas,
    //    error: errorVideoItemsDatas
    //} = useFetch(videoItemsDatasUrl);

    useEffect(() => {
        fetch(videoApiUrl)
            .then((res) => res.json())
            .then((repos) => {
                setRecommendationItemsDatas(repos.recommendation);
                if (Object.keys(repos.current).length != 0) {
                    setCurrentVideo(repos.current);
                }
            });
    }, [videoApiUrl]);

    function viewed() {
        props.setViewed(currentVideo.ID);
        var nextVideo = recommendationItemsDatas.find(item => !props.viewedVideos.includes(item.ID));
        query.set('v', nextVideo.ID);
        setTimeout(() => { navigate(`/play?${query.toString()}`, { replace: true }) }, 1000);
    }
    //useEffect(() => {
    //    console.log("toutoune2")
    //    let requested = videoItemsDatas.find(v => v.ID === videoId);
    //    if (requested) {
    //        setCurrentVideo(requested);
    //    }
    //}, [videoId, videoItemsDatasUrl]);

    //useEffect(() => {
    //    console.log(videoId, loadingVideoItemsDatas, errorVideoItemsDatas, videoItemsDatas)
    //    if (loadingVideoItemsDatas === false) {
    //        let requested = videoItemsDatas.find(e => e.ID === videoId);
    //        if (requested) {
    //            setCurrentVideo(requested);
    //        }
    //    }
    //}, [videoItemsDatas, videoId, loadingVideoItemsDatas])

    return <>
        <div style={{ margin: '0 10px' }}>
            <MyVideoPlayer viewed={viewed} url={hd ? (currentVideo.streamHdUrl && currentVideo.streamHdUrl) : (currentVideo.streamUrl && currentVideo.streamUrl)} otherVignetteUrl={otherVideo.imgUrl && otherVideo.imgUrl} />
        </div>
        <Description
            otherVideo={otherVideo}
            currentVideo={currentVideo}
        />
        <SpeControls hd={hd} setHd={setHd} downloadUrl={currentVideo.downloadUrl} flushHistory={props.flushHistory} />
        <Recomendation
            className='clavid-recomendation'
            currentVideo={currentVideo}
            setOtherVideo={setOtherVideo}
            videoItemsDatas={recommendationItemsDatas}
            viewedVideos={props.viewedVideos}
        />
    </>;
}
export default PlayPage;