import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Mozaique from './components/Mozaique';
import PlayPage from './components/PlayPage';
import './App.css';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Cookies from 'universal-cookie';

const defaultVideo = {
  'ID': 0,
  'title': '',
  'streamUrl': '',
  'streamHdUrl': '',
  'imgUrl': '',
  'description': '',
  'tags': [],
  'duration': '',
  'date': ''
}

function App(props) {
  const cookies = new Cookies();
  var utilCookie = cookies.get('viewed');
  if (typeof utilCookie === 'undefined') {
    utilCookie = [];
  }
  const [viewedVideos, setViewedVideos] = useState(utilCookie);

  function viewed(id) {
    var l = viewedVideos;
    if (!l.includes(id)) {
      l.push(id);
      setViewedVideos(l);
      cookies.set('viewed', viewedVideos, { path: '/' });
    }
  }

  function flushHistory() {
    setViewedVideos([]);
    cookies.set('viewed', [], { path: '/' });
  }

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/play" element={<PlayPage viewedVideos={viewedVideos} setViewed={viewed} flushHistory={flushHistory}/>} />
          <Route path="/*" element={<Mozaique viewedVideos={viewedVideos} />} />
        </Routes>
      </div >
    </BrowserRouter>
  );
}

export default App;
