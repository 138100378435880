import './VideoItem.css';
import { useLocation, useNavigate } from 'react-router-dom'

function VideoItem(props) {
    const query = new URLSearchParams(useLocation().search);
    const videoItemData = props.videoItemData;
    const tagsOfCurrentVideo = props.currentVideo?.tags || [];
    const isInSearch = !!query.get('q')
    const searchWords = isInSearch ? query.get('q').split(' ') : [];
    const navigate = useNavigate();

    function handleEnter(enter) {
        if (enter) {
            props.setOtherVideo(videoItemData);
        } else {
            //props.setOtherVideo("props.currentVideo");
            props.setOtherVideo({});
        }
    }

    function handleClick(e) {
        query.set('v', videoItemData.ID);
        query.delete('q');
        navigate(`/play?${query.toString()}`, { replace: true });
    }

    function handleTagsClick(e, tag) {
        e.stopPropagation();
        query.set('q', tag);
        navigate(`/play?${query.toString()}`, { replace: true });
    }

    return (
        <div
            className="clavid-videoitem"
            onClick={handleClick}
        >
            <img
                className='clavid-videoitem-mignature'
                src={videoItemData.imgUrl}
                alt={videoItemData.title}
                onMouseEnter={() => handleEnter(true)}
                onMouseOut={() => handleEnter(false)}
            />
            <span className='clavid-video-title'>{props.viewed?'✅ ':''}{videoItemData.title}</span>
            <span className="clavid-video-date">{videoItemData.dateString}</span>
            <div className='clavid-tag-list'>
                {videoItemData.tags.map(function (tag, index) {
                    const key = tag + "-" + index
                    const matchingClass = isInSearch ? (searchWords.includes(tag) ? 'clavid-tag matching' : 'clavid-tag') : (tagsOfCurrentVideo.includes(tag) ? 'clavid-tag matching' : 'clavid-tag');
                    return <button key={key} className={matchingClass} onClick={(e) => handleTagsClick(e, tag)}>{tag}</button>
                })}
            </div>
            <div className='clavid-duration'>{videoItemData.duration}</div>
        </div>
    );
}

export default VideoItem;