import React, { useState, useEffect } from 'react';
import './SpeControls.css';

function SpeControls({ hd, setHd, downloadUrl, flushHistory }) {
    return (
        <div className="clavid-specontrols">
            <button className='clavid-specontrols-button' title="Change video quality" onClick={() => setHd(!hd)}>{hd ? 'HD' : 'SD'}</button>
            <a className='clavid-specontrols-button' title="Download Original File" href={downloadUrl}>⇩</a>
            <button className='clavid-specontrols-button' title="Flush history" onClick={flushHistory}>🗑</button>
        </div>
    );
}

export default SpeControls;