import { useEffect, createRef } from 'react';
import './Recomendation.css';
import VideoItem from './VideoItem';

function Recomendation(props) {
    const myRef = createRef()
    useEffect(() => {
        myRef.current.scrollTo(0, 0);
    }, [props.videoItemsDatas])

    return (
        <div className="clavid-recomendation" ref={myRef}>
            {props.videoItemsDatas && props.videoItemsDatas.map(function (videoItemData, index) {
                const viewed = props.viewedVideos.includes(videoItemData.ID);
                return (
                    <VideoItem
                        key={index}
                        videoItemData={videoItemData}
                        currentVideo={props.currentVideo}
                        setOtherVideo={props.setOtherVideo}
                        viewed={viewed}
                    />
                );
            })}
        </div>
    );
}

export default Recomendation;