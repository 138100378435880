import { useState, useEffect } from 'react';
import './Mosaique.css';
import VideoItem from './VideoItem';

function Mozaique() {
    const [recommendationItemsDatas, setRecommendationItemsDatas] = useState([]);
    const videoApiUrl = `/videos/all`
    useEffect(() => {
        fetch(videoApiUrl)
            .then((res) => res.json())
            .then((repos) => {
                setRecommendationItemsDatas([...repos].reverse());
            });
    }, [videoApiUrl]);

    return (
        <div className="clavid-mozaique">
            {recommendationItemsDatas.map(function (videoItemData, index) {
                return (
                    <VideoItem
                        key={index}
                        videoItemData={videoItemData}
                    />
                );
            })}
        </div>
    );
}

export default Mozaique;