import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './Header.css';
import logo from '../assets/logo.png'

function Header() {
    const query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");

    function search() {
        query.set('q', searchText);
        navigate(`/play?${query.toString()}`, { replace: true });
    }
    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            search();
        }
    }

    return (
        <div className='clavid-header'>
            <div className='clavid-header-left'>
                <a href='//clafoutis.fr.nf'><img width="48em" src={logo} /></a>
                <span> <a className='clavid-header-link' href='//videos.clafoutis.fr.nf/'>▶ Clafoutis Videos</a></span>
            </div>
            <div className='clavid-header-right'>
                <input className='clavid-input' placeholder="..." type="text" value={searchText} onKeyUp={handleKeyUp} onChange={(e) => setSearchText(e.target.value)} />
                <button className="clavid-button" onClick={search}>🔍</button>
            </div>
        </div>
    );
}

export default Header;