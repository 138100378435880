import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import './Description.css';

function Description(props) {
    const otherVideo = props.otherVideo;
    const currentVideo = props.currentVideo;

    return (
        <div className="clavid-description">
            <span className='clavid-title'>{otherVideo.title ? otherVideo.title : currentVideo.title}</span>
            <div className='clavid-tag-list'>
                {(props.otherVideo.tags ? props.otherVideo.tags : props.currentVideo.tags).map(function (tag, index) {
                    const key = tag + "-" + index
                    return <button key={key} className='clavid-tag'>{tag}</button>
                })}
            </div>
            <span style={{ whiteSpace: "pre-wrap" }}>{otherVideo.description ? otherVideo.description : currentVideo.description}</span>
            <span >{otherVideo.datestring ? otherVideo.datestring : currentVideo.datestring}</span>
        </div>
    );
}

export default Description;